<template>
  <header
      style="width: 100%; height: 48px; background: #FFFFFF;display: flex;justify-content: space-between;">
    <div
        style="display: flex;width: 232px; height: 48px;font-weight: 500;font-family: PingFang SC-Medium; margin-left: 24px; font-size: 23px;align-items: center;">
      票靓·微报销
    </div>
    <div style="display: flex;">
      <div :class="[state.routerPath==='/'?'header-button--active':'','header-button']"
           @click="goRouter('/')">产品
      </div>
      <!--      <div :class="[state.routerPath==='Price'?'header-button&#45;&#45;active':'','header-button']"-->
      <!--           @click="goRouter('Price')">价格-->
      <!--      </div>-->
      <!--      <div :class="[state.routerPath==='Download'?'header-button&#45;&#45;active':'','header-button']"-->
      <!--           @click="goRouter('Download')">下载-->
      <!--      </div>-->
      <div class="header-button" @click="login">登录</div>
      <!--      <div class="header-button">注册</div>-->
    </div>
  </header>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";
import {onBeforeUnmount, reactive} from "vue";
import Bus from "/@/utils/bus";

const router = useRouter()
const state = reactive({
  routerPath: "/"
})

function goRouter(r) {
  state.routerPath = r
  router.push(r)
}

function login() {
  window.open('https://pre.powertax.net.cn/WellInvoiceCostHtml', "_blank")
}

Bus.on('goRouter', (msg) => {
  goRouter(msg)
})
onBeforeUnmount(() => {
  Bus.off('goRouter')
})
</script>

<style scoped>
.header-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 48px;
}

.header-button--active {
  background: #41C7C0;
  color: white;
}
</style>
