<template>
  <!-- 个人信息 -->
  <!-- <div class="message"> -->
  <transition name="plus-icon">
    <div class="fixed-left message-left" v-if="show1">
      <p>如需联系，请拨打我们的热线电话</p>
      <p>400-059-6663</p>
      <!-- <p>137******** 张先生</p>
      <p>186******** 赵先生</p> -->
    </div>
  </transition>
  <div
    class="fixed-right message-right"
    @mouseover="showFn4()"
    @mouseleave="showFn7()"
  >
    <img src="../../assets/img/fixed-img1.png" alt="" />
  </div>
  <div class="boult1" @click="showFn1()">
    <img v-if="hide1" src="../../assets/img/chevron-states1.png" alt="" />
    <img v-else src="../../assets/img/chevron-states2.png" alt="" />
  </div>
  <!-- </div> -->
  <!-- 企业微信二维码 -->
  <div class="code">
    <transition name="plus-icon">
      <div class="fixed-left code-left" v-if="show2">
        <p>给您提供更专业的服务，请添加客服企业微信</p>
        <div class="code-img">
          <img class="code-img1" src="../../assets/img/fixed-img4.png" alt="" />
          <img class="code-img2" src="../../assets/img/fixed-img5.png" alt="" />
        </div>
      </div>
    </transition>
    <div
      class="fixed-right code-right"
      @mouseover="showFn5()"
      @mouseleave="showFn8()"
    >
      <img src="../../assets/img/fixed-img2.png" alt="" />
    </div>
    <div class="boult2" @click="showFn2()">
      <img v-if="hide2" src="../../assets/img/chevron-states1.png" alt="" />
      <img v-else src="../..//assets/img/chevron-states2.png" alt="" />
    </div>
  </div>
  <!-- 小程序码 -->
  <div class="program">
    <transition name="plus-icon">
      <div class="fixed-left program-left" v-if="show3">
        <p>请扫描小程序二维码进行体验</p>
        <img src="../../assets/img/fixed-img6.png" alt="" />
      </div>
    </transition>
    <div
      class="fixed-right program-right"
      @mouseover="showFn6()"
      @mouseleave="showFn9()"
    >
      <img src="../../assets/img/fixed-img7.png" alt="" />
    </div>
    <div class="boult3" @click="showFn3()">
      <img v-if="hide3" src="../../assets/img/chevron-states1.png" alt="" />
      <img v-else src="../../assets/img/chevron-states2.png" alt="" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const show1 = ref(true);
const show2 = ref(true);
const show3 = ref(true);
const hide1 = ref(true);
const hide2 = ref(true);
const hide3 = ref(true);
function showFn1() {
  show1.value = !show1.value;
  hide1.value = !hide1.value;
}
function showFn2() {
  show2.value = !show2.value;
  hide2.value = !hide2.value;
}
function showFn3() {
  show3.value = !show3.value;
  hide3.value = !hide3.value;
}
function showFn4() {
  show1.value = true;
  // hide1.value = true
}
function showFn5() {
  show2.value = true;
  // hide2.value = true
}
function showFn6() {
  show3.value = true;
  // hide3.value = true
}
function showFn7() {
  show1.value = false;
  hide1.value = false;
}
function showFn8() {
  show2.value = false;
  hide2.value = false;
}
function showFn9() {
  show3.value = false;
  hide3.value = false;
}
</script>
<style scoped>
/* .fixed {
  position: fixed;
  right: 0px;
  bottom: 14px;
  width: 440px;
  height: 505px;
} */
/* .message {
  position: fixed;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 137px;
} */
.code {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 169px;
}
.program {
  display: flex;
  align-items: center;
  height: 169px;
}
.fixed-left {
  width: 343px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.message-left {
  position: fixed;
  bottom: 373px;
  right: 104px;
  height: 100px;
}
.code-left {
  position: fixed;
  bottom: 193px;
  right: 104px;
  height: 169px;
}
.program-left {
  position: fixed;
  bottom: 14px;
  right: 104px;
  height: 169px;
}
.fixed-left p {
  margin-left: 57px;
  font-size: 18px;
  height: 34px;
  line-height: 34px;
  font-family: SourceHanSansCN-Regular;
  color: #797979;
}
.message-left p:nth-child(1) {
  margin-top: 20px;
  font-size: 14px;
}
.message-left p:nth-child(2) {
  /* margin-top: 20px; */
  font-size: 18px;
  margin-left: 100px;
}
.code-left p {
  font-size: 14px;
  margin-left: 31px;
  margin-top: 4px;
}
.code-left .code-img {
  display: flex;
}
.code-left .code-img1 {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 120px;
  height: 120px;
  margin-left: 45px;
  /* background-image: url(../../assets/img/fixed-img4.png); */
}
.code-left .code-img2 {
  width: 120px;
  height: 120px;
  margin-left: 10px;
}
.program-left p {
  font-size: 14px;
}
.program-left img {
  margin-left: 105px;
}
.fixed-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 61px;
  height: 61px;
  border-radius: 50%;
  background: #07b9b9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.fixed-right:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    1px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.message-right {
  position: fixed;
  bottom: 392px;
  right: 37px;
}
.code-right {
  position: fixed;
  bottom: 249px;
  right: 37px;
}
.program-right {
  position: fixed;
  bottom: 70px;
  right: 37px;
}
.program-right img {
  width: 25px;
}
.boult1 {
  position: fixed;
  right: 5px;
  bottom: 410px;
}
.boult2 {
  position: fixed;
  right: 5px;
  bottom: 261px;
}
.boult3 {
  position: fixed;
  right: 5px;
  bottom: 81px;
}
.plus-icon-enter-active {
  transition: opacity 0.7s;
}
.plus-icon-enter-from {
  opacity: 0;
}

.plus-icon-leave-active {
  transition: opacity 0.7s;
}
.plus-icon-leave-to {
  opacity: 0;
}
</style>