<template>
  <div style="position: absolute;z-index: 100; width: 100%;height: 300px;display: flex;
padding-top: 60px;padding-left: 100px;flex-direction: column;">
    <div
        style="width: 100%; font-size: 42px; font-weight: 500; color: #000000; line-height: 52px;opacity: 1;display: flex;">
      票靓·微报销——
    </div>
    <div
        style="width: 100%;display: flex;font-size: 19px; font-weight: 400; color: #000000; line-height: 23px;margin-top: 12px;">
      服务中小微，助力大财税
    </div>
    <div style="display: flex;margin-top: 68px;">
      <div
          style="width: 112px; height: 40px;color:#41C7C0;background-color: white;display: flex;justify-content: center;align-items: center;">
        申请试用
      </div>
      <div
          style="width: 112px; height: 40px;color:white;background-color: #41C7C0;display: flex;justify-content: center;align-items: center;margin-left: 24px;">
        立即购买
      </div>
    </div>
  </div>
  <n-carousel show-arrow autoplay draggable touchable style="position: relative;">
    <img class="carousel-img" src="../../assets/img/carousel1.png" alt=""/>
    <img class="carousel-img" src="../../assets/img/carousel1.png" alt=""/>
    <template #arrow="{ prev, next }">
      <div class="custom-arrow">
        <button type="button" class="curtom-arrow--left" @click="prev">
          <n-icon>
            <ArrowBack/>
          </n-icon>
        </button>
        <button type="button" class="curtom-arrow--right" @click="next">
          <n-icon>
            <ArrowForward/>
          </n-icon>
        </button>
      </div>
    </template>
    <template #dots="{ total, currentIndex, to }">
      <ul class="custom-dots">
        <li
            v-for="index of total"
            :key="index"
            :class="{ ['is-active']: currentIndex === index - 1 }"
            @click="to(index - 1)"
        />
      </ul>
    </template>
  </n-carousel>
</template>

<script lang="ts">
import {ArrowBack, ArrowForward} from '@vicons/ionicons5'
import {NCarousel} from 'naive-ui'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    ArrowBack,
    ArrowForward,
    NCarousel
  }
})
</script>

<style scoped>
.n-carousel {
  background-image:  linear-gradient(to right , #fff, #f4fffe);
}

.carousel-img {
  float: right;
  height: 415px;
  object-fit: cover;
}

.custom-arrow {
  display: flex;
  position: absolute;
  bottom: 25px;
  right: 10px;
}

.custom-arrow button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 12px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-width: 0;
  border-radius: 8px;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.custom-arrow button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-arrow button:active {
  transform: scale(0.95);
  transform-origin: center;
}

.custom-dots {
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 80px;
  left: 100px;
}

.custom-dots li {
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 3px;
  border-radius: 4px;
  background-color: #D7DBE0;
  transition: width 0.3s, background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.custom-dots li.is-active {
  width: 40px;

  background: #41C7C0;
}

.custom-arrow > button {
  /*background-color: #D7DBE0;*/
  padding: 8px;
}

.custom-arrow > button > .n-icon {
  color: #41C7C0;
  font-size: 30px;
}
</style>
