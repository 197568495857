<template>
  <carousel></carousel>
  <section
    id="body1"
    style="display: flex; width: 100%; height: 330px; flex-direction: column"
  >
    <div
      style="
        display: flex;
        font-size: 34px;
        font-weight: 500;
        color: #222222;
        justify-content: center;
        align-items: center;
        margin-top: 56px;
        margin-bottom: 43px;
      "
    >
      小微企业线上报销神器
    </div>
    <div
      style="
        display: flex;
        height: 145px;
        padding-right: 100px;
        padding-left: 100px;
        justify-content: space-between;
      "
    >
      <div
        v-for="(item, index) in state.body1Texts"
        @mousemove="setBody1Index(index)"
        style="
          width: 200px;
          display: flex;
          height: 175px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          :src="state.body1Imgs[index]"
          alt=""
          style="height: 107px; width: 204px; display: flex"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
            font-size: 20px;
            width: 204px;
            height: 56px;
            font-weight: 500;
            font-family: PingFang SC-Medium;
            margin-top: 22px;
            padding-bottom: 28px;
          "
          :class="[index === state.body1Index ? 'button-active' : '']"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </section>
  <section
    id="body2"
    style="
      display: flex;
      width: 100%;
      height: 573px;
      flex-direction: column;
      background-color: #f4f7f8;
      position: relative;
    "
  >
    <div
      style="
        position: absolute;
        width: 530px;
        height: 100%;
        top: 145px;
        left: 100px;
        flex-direction: column;
        display: flex;
      "
    >
      <div
        style="
          font-size: 23px;
          font-weight: 500;
          color: #222222;
          margin-bottom: 37px;
        "
        v-html="state.body2TitleTexts[state.body1Index]"
      ></div>
      <div
        v-for="item in state.body2Texts[state.body1Index]"
        class="body2-li"
        style="
          display: flex;
          color: #a2a4a6;
          margin-top: 5px;
          font-size: 15px;
          width: 497px;
        "
      >
        {{ item }}
      </div>
    </div>
    <div>
      <img
        :src="state.body2Imgs[state.body1Index]"
        style="height: 573px; float: right"
      />
    </div>
  </section>
  <section
    id="body3"
    style="
      display: flex;
      width: 100%;
      height: 140px;
      flex-direction: column;
      background-color: white;
      position: relative;
      font-size: 32px;
      font-weight: 500;
      color: #222222;
      justify-content: center;
      align-items: center;
    "
  >
    个人票据管理中心
  </section>
  <section
    id="body4"
    style="
      display: flex;
      width: 100%;
      height: 592px;
      flex-direction: column;
      background-color: white;
      position: relative;
    "
  >
    <div style="display: flex; flex-direction: row; position: relative">
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            支持多种票据录入方式
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            拍照录入：采用OCR识别技术，具备容错性高、实时性高、识别准确率高等特性。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            扫码录入：手机秒变扫码枪，查验快捷、准确。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            手工录入：手机秒变扫码枪，查验快捷、准确。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            数据对接：支持手工添加票据信息，支持全票种录入。区别于传统报销电子发票开具-发送邮箱-打印-裁切的报销模式。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img1.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            支持录入票据种类全
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            支持录入市面常见报销票据，涵盖各种纸质发票、电子发票、其他可报销单据等。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            根据税局或财政部等部门票种更新情况，系统随时更新发票种类。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img2.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; position: relative">
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            支持录入票据种字段全
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            支持录入票据字段齐全，涵盖所有报销相关的数据字段，如发票类型、开票日期、金额等。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            支持对小额多量的票据批量录入，直接录入票据总张数及总金额，减轻工作量。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img3.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            个人票包随心分类
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            预置默认票包，添加发票即可查看，形成个人票据电子档案。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            支持票包自定义，随心票据分类。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img4.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
    </div>
  </section>
  <section
    id="body5"
    style="
      display: flex;
      width: 100%;
      height: 317px;
      flex-direction: column;
      background-color: #f4f7f8;
    "
  >
    <div
      style="
        display: flex;
        height: 140px;
        font-size: 34px;
        font-weight: 500;
        color: #222222;
        justify-content: center;
        align-items: center;
        font-family: PingFang SC-Medium;
      "
    >
      费控管理工具，助力企业降本增效
    </div>
    <div
      style="
        display: flex;
        height: 170px;
        justify-content: space-between;
        padding-right: 100px;
        padding-left: 100px;
      "
    >
      <div
        v-for="(item, index) in state.body5TitleTexts"
        @mousemove="setBody5Index(index)"
        style="
          width: 200px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          :src="state.body5Imgs[index]"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
            font-family: PingFang SC-Medium;
            width: 204px;
            height: 56px;
            padding-bottom: 28px;
          "
          :class="[index === state.body5Index ? 'button-active' : '']"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </section>
  <section
    id="body6"
    style="
      display: flex;
      width: 100%;
      height: 432px;
      flex-direction: column;
      background-color: #f4f7f8;
      position: relative;
    "
  >
    <div
      style="
        position: absolute;
        width: 380px;
        height: 100%;
        top: 101px;
        left: 100px;
        flex-direction: column;
        display: flex;
      "
    >
      <div
        style="
          font-size: 23px;
          font-weight: 500;
          color: #222222;
          margin-bottom: 37px;
        "
      >
        {{ state.body6TitleTexts[state.body5Index] }}
      </div>
      <div
        v-for="item in state.body6Texts[state.body5Index]"
        class="body2-li"
        style="display: flex; color: #a2a4a6; margin-top: 5px; font-size: 15px"
      >
        {{ item }}
      </div>
    </div>
    <div>
      <img
        :src="state.body6Imgs[state.body5Index]"
        style="height: 432px; float: right"
      />
    </div>
  </section>
  <section
    id="body7"
    style="display: flex; width: 100%; height: 300px; flex-direction: column"
  >
    <div
      style="
        display: flex;
        height: 160px;
        font-size: 32px;
        font-weight: 500;
        color: #222222;
        justify-content: center;
        align-items: center;
      "
    >
      资深财税领域专家，为您提供专业支撑
    </div>
    <div
      style="
        display: flex;
        height: 145px;
        justify-content: space-between;
        padding-right: 100px;
        padding-left: 100px;
      "
    >
      <div
        style="
          width: 204px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section7-img4.png"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
            width: 204px;
            height: 56px;
            padding-bottom: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #41c7c0;
            margin-top: 20px;
            border-bottom: 3px solid #41c7c0;
          "
        >
          信息安全保障
        </div>
      </div>
      <div
        style="
          width: 204px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section7-img5.png"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
             width: 204px;
            height: 56px;
            padding-bottom: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #222222;
            margin-top: 20px;
          "
        >
          业务专业度支撑
        </div>
      </div>
      <div
        style="
          width: 204px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section7-img6.png"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
             width: 204px;
            height: 56px;
            padding-bottom: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #222222;
            margin-top: 20px;
          "
        >
          技术专业度支撑
        </div>
      </div>
    </div>
  </section>
  <section
    id="body8"
    style="
      display: flex;
      width: 100%;
      height: 432px;
      flex-direction: column;
      position: relative;
    "
  >
    <div
      style="
        display: flex;
        height: 432px;
        justify-content: space-between;
        align-items: center;
        padding-right: 100px;
        padding-left: 100px;
      "
    >
      <div
        style="
          width: 280px;
          height: 246px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img1.png"
          alt=""
          style="height: 156px; width: 156px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            flex-grow: 1;
            font-size: 19px;
            font-weight: 500;
            margin-top: 20px;
          "
        >
          公安部三级等保
        </div>
      </div>
      <div
        style="
          width: 280px;
          height: 246px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img2.png"
          alt=""
          style="height: 156px; width: 156px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            flex-grow: 1;
            font-size: 19px;
            font-weight: 500;
            margin-top: 20px;
          "
        >
          ISO27001
        </div>
      </div>
      <div
        style="
          width: 280px;
          height: 246px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img3.png"
          alt=""
          style="height: 156px; width: 156px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            flex-grow: 1;
            font-size: 19px;
            font-weight: 500;
            margin-top: 20px;
          "
        >
          ISO20000
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Carousel from "./Carousel.vue";
import { reactive, watchEffect } from "vue";
import sec1Img5 from "/@/assets/img/section1-img5.png";
import sec1Img6 from "/@/assets/img/section1-img6.png";
import sec1Img7 from "/@/assets/img/section1-img7.png";
import sec1Img8 from "/@/assets/img/section1-img8.png";
import sec2Img1 from "/@/assets/img/section2-img1.png";
import sec2Img2 from "/@/assets/img/section2-img2.png";
import sec2Img3 from "/@/assets/img/section2-img3.png";
import sec2Img4 from "/@/assets/img/section2-img4.png";
import sec5Img4 from "/@/assets/img/section5-img4.png";
import sec5Img5 from "/@/assets/img/section5-img5.png";
import sec5Img6 from "/@/assets/img/section5-img6.png";
import sec6Img1 from "/@/assets/img/section6-img1.png";
import sec6Img2 from "/@/assets/img/section6-img2.png";
import sec6Img3 from "/@/assets/img/section6-img3.png";

const state = reactive({
  body1Index: 0,
  body1Texts: [
    '线上"无触式"闭环流程',
    "报销场景、规则自定义",
    "智能化费用归集",
    "报销风险管控",
  ],
  body1Imgs: [sec1Img5, sec1Img6, sec1Img7, sec1Img8],
  body2Imgs: [sec2Img1, sec2Img2, sec2Img3, sec2Img4],
  body2TitleTexts: [
    `报销不受时间限制，<span style="font-size: 35px; color:#41C7C0; ">&nbsp;提高&nbsp;</span>报销效率`,
    `智能化<span style="font-size: 35px; color:#41C7C0; ">&nbsp;报销规则&nbsp;</span>，应对全方位报销场景`,
    `智能匹配发票<span style="font-size: 35px; color:#8684DC; ">&nbsp;费用归类&nbsp;</span>，实现报销人一键添票 `,
    `报销风险<span style="font-size: 35px; color:#41C7C0; ">&nbsp;管控&nbsp;</span> `,
  ],
  body2Texts: [
    [
      "员工无需线下找领导审批，缩短审批时间。",
      "简化报销流程，让员工避免繁琐的报销流程，全心投入工作，提升报销体验。",
      "手机即可完成报销全流程，无需打印发票、贴票、寻找报销底联，不受报销工具限制。",
      "摆脱传统纸质报销打印，节约纸张等公司运营成本。",
    ],
    [
      "内置日常报销、差旅报销、对公付款等多种基本报销场景，满足企业基本需要。",
      "支持根据公司报销特点自定义报销类别及报销规则，瞬间满足企业报销需求差异化。",
      "实现一平台多场景全流程的费用管理模式。",
    ],
    [
      "预置企业报销费用归类规则，即可在报销过程中智能匹配发票费用归类，实现报销人一键添票，省心、省时、省事。 ",
      "财务人员节约审票、审单时间，审批时只需关注票据信息，智能、规范、准确。 ",
      "归集规则可自由配置，同时支持企业自定义适用于本企业规则。",
      "标准化API接口，随时对接各类财务软件",
    ],
    [
      "票面信息异常预警：当获得的发票抬头与本企业抬头不一致、发票销售方异常、开票项目明细不符合报销要求时，系统可自动给出提示，保证报销票据合规性。 ",
      "虚假发票识别、预警：系统提供发票查验服务，可辨别发票真伪，对查验不一致或查无此票的票据进行报销拦截，防范报销风险。",
      " 重复报销预警：当员工报销的发票在系统中有其他报销事项时，及时给出提示，报账报销票据的唯一性。 ",
      "发票状态异常变更预警：系统提供的发票查验服务，可以查验出发票状态，当发票状态为红冲或者异常时，可能存在问题，系统自动做出提醒。",
    ],
  ],
  body5Index: 0,
  body5TitleTexts: [
    "费用数据可视化展示",
    "费用数据统计分析",
    "企业费用电子档案",
  ],
  body6TitleTexts: [
    "企业费用一目了然",
    "多维度统计分析",
    "助力企业完成标准档案数字化",
  ],
  body6Texts: [
    [
      "通过可视化数据展示项目支出占比、部门支出占比等信息，让企业费用一目了然。",
    ],
    [
      "多维度统计：支持按照时间、费用类别、项目、组织架构等维度进行费用统计。",
      "为您提供深度数据分析，包括：费用异常请款预警，费用请款趋势分析，企业自定义数据分析。",
    ],
    [
      "为企业构建费用电子档案，助力企业完成财务管理电子化转型。",
      "实现财务管理数据长期或永久保存。",
    ],
  ],
  body5Imgs: [sec5Img4, sec5Img5, sec5Img6],
  body6Imgs: [sec6Img1, sec6Img2, sec6Img3],
});

function setBody1Index(index) {
  state.body1Index = index;
}

function setBody5Index(index) {
  state.body5Index = index;
}

watchEffect(() => {
  console.log(state.body1Index);
  console.log(state.body5Index);
});
</script>

<style scoped>
.body2-li {
  padding-left: 12px;
}

.body2-li::before {
  position: absolute;
  left: 0;
  font-size: 5px;
  content: "●";
}

.button-active {
  color: #41c7c0;
  border-bottom: 3px solid #41c7c0;
}
</style>
