<template>
  <footer>
    <div style="display: flex; width: 100%;height: 193px;background-color: #F4F7F8;padding-left: 100px;
padding-top: 28px;padding-right: 100px;position: relative;">
      <div style="display: flex;flex-direction: column;width: 136px;">
        <div style="display: flex; color: #222222;font-weight: 400;font-size: 13px;">产品概况</div>
        <a style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 15px;"
           @click="goRouter('/')">产品介绍
        </a>
        <a style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 5px;"
           @click="goRouter('Price')">价格
        </a>
        <a style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 5px;"
           @click="goRouter('Download')">下载
        </a>
      </div>
      <div style="display: flex;flex-direction: column;width: 450px;">
        <div style="display: flex; color: #222222;font-weight: 400;font-size: 13px;">联系我们</div>
        <div style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 15px;">
          客服电话：400-8539-228转2
        </div>
        <div style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 5px;">
          电子邮箱：haiwen_pl@ccitc.net.cn
        </div>
        <div style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 5px;">
          联系地址：广东省广州市天河区体育东路122号羊城国际商贸中心西塔22楼
        </div>
      </div>
      <div style="display: flex;flex-direction: column;width: 336px;">
        <div style="display: flex; color: #222222;font-weight: 400;font-size: 13px;">友情链接</div>
        <a style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 15px;"
           href="http://www.powertax.com.cn/" target="_blank">海闻科技</a>
        <a style="display: flex; color: #798195;font-weight: 400;font-size: 11px;margin-top: 5px;"
           href="http://www.upitc.com.cn/ccitc/" target="_blank">海华税务</a>
      </div>
      <img src="../../assets/img/minprogrom.jpg" style="width: 100px;height: 100px;"
           alt="票靓小程序">
    </div>

    <div
        style="display: flex;width: 100%;height: 96px;background-color: #494949;justify-content: center;align-items: center;flex-direction: column;">
      <div style="display: flex;flex-direction: row;">
        <img src="../../assets/img/gongan.png"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010509"
           style="font-size: 13px; color:#939393;">粤公网安备 44010602010509号</a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/"
           style="font-size: 13px; color:#939393;display: flex;">粤ICP备17095452号</a>
      </div>
      <div style="display: flex;font-size: 13px; color:#939393;margin-top: 5px;">海闻科技有限公司版权所有</div>
      <div style="display: flex;font-size: 13px; color:#939393;margin-top: 5px;">为保证浏览体验，建议使用谷歌浏览器</div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import Bus from '/@/utils/bus'
import {onBeforeUnmount} from "vue";


function goRouter(r) {
  Bus.emit('goRouter', r)
}

onBeforeUnmount(() => {
  Bus.off('goRouter')
})
</script>

<style scoped>

</style>
