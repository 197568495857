import {createRouter, createWebHashHistory} from 'vue-router';
// 1. 定义路由组件.
import Index from "/@/views/index/Index.vue";
import Download from "/@/views/index/Download.vue";
import Price from "/@/views/index/Price.vue";
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
const routes = [
    {path: '/', component: Index, name: '产品'},
    {path: '/Price', component: Price, name: '价格'},
    {path: '/Download', component: Download, name: '下载'},
]

// 3. 创建路由实例并传递 `routes` 配置
export const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写,
    // 是否应该禁止尾部斜杠。默认为假
    strict: true,
    scrollBehavior: () => ({left: 0, top: 0}),
})


